<template>
  <form-wizard
    color="#4284f4"
    @on-change="handleOnChange"
  >
    <tab-content
      title="Create campaign"
      :before-change="() => $refs.createStep.validateStep()"
    >
      <CreateStep
        ref="createStep"
        :popup="popup"
        @done="(popup) => $emit('popup-changed', popup)"
        @loading="loadingState"
      />
    </tab-content>
    <tab-content
      title="Coupon Wizard"
      :before-change="() => $refs.wizardStep.validateStep()"
    >
      <wizardStep
        ref="wizardStep"
        :popup="popup"
        @done="(popup) => $emit('popup-changed', popup)"
        @loading="loadingState"
      />
    </tab-content>
    <tab-content title="Code Generation">
      <CodeGenerationStep :coupon-script="couponScript" :popup="popup" />
    </tab-content>
    <template slot="next">
      <button
        tabindex="-1"
        type="button"
        class="wizard-btn"
        style="color: #fff;background-color: #4284f4;border-color: #4284f4;"
        :disabled="isLoading"
      >
        <b-spinner v-if="isLoading" small />
        <span v-else>Next</span>
      </button>
    </template>
    <template slot="finish">
      <router-link
        v-if="!popup.new_keyword && popup.automation_id"
        :to="{ name: 'business.automations.edit', params: { id: popup.automation_id } }"
        class="wizard-btn ml-2" style="color: #fff;background-color: #4284f4;border-color: #4284f4;">
        Go To Automation
      </router-link>
      <a
        href="javascript:;"
        class="wizard-btn ml-2"
        style="color: #fff;background-color: #4284f4;border-color: #4284f4;"
        @click.prevent="handleOnComplete"
      >
        Finish
      </a>
    </template>
  </form-wizard>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import CodeGenerationStep from './Components/CodeGenerationStep'
import CreateStep from './Components/CreateStep'
import WizardStep from './Components/WizardStep'

export default {
  components: {
    FormWizard,
    TabContent,
    CodeGenerationStep,
    CreateStep,
    WizardStep,
  },

  props: {
    popup: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
      couponScript: null,
    }
  },

  methods: {
    loadingState(loading) {
      this.isLoading = loading
    },

    getScript() {
      this.$store
        .dispatch('coupon/getScript', this.popup.id)
        .then((script) => {
          this.couponScript = script
        })
        .catch(() => {})
    },

    handleOnChange(prevIndex, nextIndex) {
      if (nextIndex === 1 && !this.couponScript) {
        this.getScript()
      }
    },

    handleOnComplete() {
      if (this.popup.new_keyword && this.popup.automation_id) {
        this.$router.push({ name: 'business.automations.edit', params: { id: this.popup.automation_id } })
      } else {
        this.$router.push({ name: 'business.coupons.index' })
      }
    },
  },
}
</script>
