<template>
  <div>
    <div class="editor-container">
      <div v-if="selectedMode === 'design'" class="design-box">
        <div class="card">
          <div class="card-body">
            <div role="tablist" class="custom-accordion ml-4">
              <b-card
                v-for="section in sections"
                :key="section.key"
                class="mb-1"
                no-body
              >
                <b-card-header header-tag="header" role="tab">
                  <a href="javascript: void(0);" class="text-black">
                    <h5
                      class="m-0 font-size-14"
                      @click="onTabClick(section.key)"
                    >
                      <i
                        :class="{
                          'uil-question-circle text-warning':
                            selectedSection !== section.key,
                          'uil-check-circle text-success':
                            selectedSection === section.key,
                        }"
                        class="uil h3 icon"
                      ></i>
                      {{ section.label }}
                    </h5>
                  </a>
                </b-card-header>
                <b-collapse
                  accordion="accordion"
                  role="tabpanel"
                  :visible="selectedSection === section.key"
                >
                  <b-card-body class="px-0">
                    <component
                      :is="section.component"
                      :section="popup.sections[section.key]"
                      :device="device"
                      :tab="selectedTab"
                    />
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <div class="preview-box">
        <div class="preview-container">
          <b-tabs>
            <b-tab
              title="Desktop"
              active
              @click="selectMode('design')"
            >
              <Widget
                ref="widget"
                :popup="popup"
                device="desktop"
                :step="selectedStep"
                @selected-block="args => onTabClick(args.block, args.tab)"
              />
            </b-tab>
            <b-tab title="Preview" @click="selectMode('preview')">
              <div>
                <WidgetPreview :popup="popup" />
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Widget from '../Widget'
import WidgetPreview from '../WidgetPreview'
import TriggerButtonSection from './TriggerButtonSection'
import StylingSection from './StylingSection'
import CloseButtonSection from './CloseButtonSection'
import PhoneStepSection from './PhoneStepSection'
import ThanksStepSection from './ThanksStepSection'
import SettingSection from './SettingSection'
import EmailStepSection  from './EmailStepSection'

export default {
  components: {
    TriggerButtonSection,
    StylingSection,
    Widget,
    WidgetPreview,
    CloseButtonSection,
    PhoneStepSection,
    ThanksStepSection,
    SettingSection,
    EmailStepSection
  },

  props: {
    popup: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      sections: [
        {
          key: 'styling',
          label: 'Pop Up Styling',
          component: StylingSection,
        },
        {
          key: 'settings',
          label: 'Pop Up Settings',
          component: SettingSection,
        },
        {
          key: 'emailStep',
          label: 'Email Setup',
          component: EmailStepSection,
        },
        {
          key: 'phoneStep',
          label: 'Text Message Setup',
          component: PhoneStepSection,
        },
        {
          key: 'thanksStep',
          label: 'Thank You Page',
          component: ThanksStepSection
        },
        {
          key: 'triggerButton',
          label: 'Pop Up Button',
          component: TriggerButtonSection,
        },
        {
          key: 'closeButton',
          label: 'Exit Button',
          component: CloseButtonSection,
        },
      ],
      selectedMode: 'design',
      selectedSection: null,
      showImageModal: false,
      loadingExportImage: false,
      device: 'desktop',
      selectedStep: 1,
      selectedTab: null,
    }
  },

  methods: {
    onChooseType(type) {
      let coupon = JSON.parse(JSON.stringify(this.coupon))
      coupon.type = type
      this.$emit('done', coupon)
    },

    onTabClick(section, tab = null) {
      this.selectedTab = tab

      if (this.selectedSection === section && !tab) {
        if (section !== 'thanksStep')
        {
          this.selectedSection = null
        }
      } else {
        this.selectedSection = section
      }
      
      switch(section) {
        case 'emailStep':
          this.selectedStep = 1
          break;
        case 'phoneStep':
          this.selectedStep = 2
          break;
        case 'thanksStep':
          this.selectedStep = 3
          break;
        default:
          break;
      }
    },

    selectMode(mode) {
      this.selectedMode = mode
    },

    async validateStep() {
      let stepValid = false

      if (this.popup.type) {
        stepValid = await this.update()
      }

      return stepValid
    },

    async update() {
      let stepValid = false
      this.$emit('loading', true)

      await this.$store
        .dispatch('coupon/update', this.popup)
        .then((popup) => {
          this.$emit('done', popup)
          this.$emit('loading', false)
          stepValid = true
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.createForm.setErrors(err.response.data.errors)
          }
          this.$emit('loading', false)
          stepValid = false
        })

      return stepValid
    },
  },
}
</script>

<style lang="scss" scoped>
.coupon-types {
  .card-img-top {
    max-width: 100%;
    width: 150px;
    margin: 0 auto;
    min-height: 150px;
  }
}

.card-wrapper {
  max-width: 1024px;
}

.editor-container {
  display: flex;
  width: 100%;
  .design-box {
    min-width: 360px;
    width: 460px;
  }
  .preview-box {
    flex: 1 1 auto;
  }
  @media screen and (max-width: 992px) {
    .design-box {
      margin: 0 auto;
    }
    flex-direction: column;
  }
}
</style>
