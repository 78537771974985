<template>
  <div v-if="section" class="row">
    <div class="col-md-6">
      <ColorInput v-model="section.BackgroundColor" label="Background color" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.BorderColor" label="Border color" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.BorderWidth" label="Border width" max="10" min="0"/>
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.MaxWidth" label="Max. width" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.MinHeight" label="Min. height" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.Roudness" label="Roudness" />
    </div>
    <div class="col-md-6">
    </div>
    <div class="col-md-12">
      <AlignInput v-model="section.Align" label="Alignment"/>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.TemplateColor" label="Template color" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.LineWidth" label="LineWidth" max="10" min="0"/>
    </div>
    <div class="col-md-12">
      <label>Template</label>
      <div class="list-templates">
        <div v-for="template in templates" :key="template.key" class="list-item"
          :class="{ active: section.Template === template.key }"
          @click="selectTemplate(template.key)">
          <img :src="require(`@assets/images/modal-templates/${template.img}.png`)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumberInput from '../../Coupons/Components/NumberInput'
import ColorInput from '../../Coupons/Components/ColorInput'
import AlignInput from '../../Coupons/Components/AlignInput'

export default {
  components: {
    NumberInput,
    ColorInput,
    AlignInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      templates: [
        { key: 'default', img: 0 },
        { key: 'round', img: 1 },
        { key: 'wave', img: 2 },
        { key: 'triangle', img: 3 },
        { key: 'slash-left', img: 4 },
        { key: 'slash-right', img: 5 },
      ]
    }
  },

  watch: {
    'section.Align': function(val) {
      if (val !== 'center')
      {
        if (this.section.BackgroundColor && this.section.BackgroundColor.length > 7)
          this.section.BackgroundColor = this.section.BackgroundColor.slice(0, 7)
      } else {
        if (this.section.BackgroundColor && this.section.BackgroundColor.length <= 7)
        {
          if (this.section.BackgroundColor.length === 4)
          {
            this.section.BackgroundColor = this.section.BackgroundColor + this.section.BackgroundColor.slice(1,4)
          }
          this.section.BackgroundColor = this.section.BackgroundColor + '90'
        }
      }
    }
  },

  methods: {
    selectTemplate(template) {
      this.$set(this.section, 'Template', template)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}

.list-templates {
  .list-item {
    width: 33.333%;
    display: inline-block;
    padding: 5px;
    cursor: pointer;
    &.active {
      img {
        border: 2px solid #4284f4;
        box-shadow: 0px 0px 3px #4284f4;
      }
    }
    img {
      width: 100%;
      border-radius: 5px;
      padding: 5px;
      border: 1px solid #ccc;
    }
  }
}
</style>
