<template>
  <div class="code-generation-step">
    <h3>Copy the widget code and install it on Your website</h3>
    <p
      >Copy the code below and paste it before the closing
      <span class="text-red">&lt;/body&gt;</span> tag in your website code.</p
    >
    <div>
      <textarea
        ref="scriptText"
        v-model="couponScript"
        name="code"
        cols="30"
        rows="6"
        class="form-control"
        readonly
        @click="copyScript"
      ></textarea>
      <div class="text-right mt-1">
        <a href="javascript:void(0)" @click="copyScript">
          Copy the code
        </a>
      </div>
    </div>
    <h5>Send the guidelines on widget installation to Your programmer</h5>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="emailForm">
      <form @submit.prevent="handleSubmit(sendEmail)">
        <div class="form-row" style="justify-content: center;">
          <div class="col-auto" style="min-width:300px">
            <TextInput
              v-model="email"
              name="email"
              rules="required|email"
              placeholder="email@example.com"
            />
          </div>
          <div class="col-auto">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="isLoading || invalid"
              @click="sendEmail"
            >
              <b-spinner v-if="isLoading" small />
              <span v-else>Send</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  props: {
    couponScript: {
      type: [String],
      default: '',
    },
    coupon: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
      email: '',
    }
  },

  methods: {
    copyScript() {
      this.$refs.scriptText.select()
      this.$refs.scriptText.setSelectionRange(0, 999999)
      document.execCommand('copy')

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },

    sendEmail() {
      this.isLoading = true

      this.$store
        .dispatch('coupon/sendEmail', {
          couponId: this.coupon.id,
          email: this.email,
        })
        .then(() => {
          this.isLoading = false
          this.email = ''
          this.$refs.emailForm.reset()
        })
        .catch(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
.code-generation-step {
  text-align: center;
  max-width: 968px;
  margin: 0 auto;
  .text-red {
    color: #bb3232;
  }
  textarea {
    resize: none;
    &[readonly] {
      background: #fff;
    }
  }
}
</style>
