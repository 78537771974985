<template>
  <div v-if="popup" class="landing-section">
    <WizardForm
      v-if="popup"
      :popup="popup"
      @popup-changed="popupChanged"
    ></WizardForm>
  </div>
</template>

<script>
import WizardForm from './WizardForm'

export default {
  components: {
    WizardForm,
  },

  data() {
    return {
      popup: null,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    this.popup = JSON.parse(
      JSON.stringify(this.$store.getters['coupon/popupOriginal'])
    )

    if (this.$route.query.loop) {
      this.landing.type = 'loop'
    }
  },

  methods: {
    popupChanged(popup) {
      this.popup = {
        new_keyword: this.popup.new_keyword,
        automation_id: this.popup.automation_id,
        ...popup,
      }
    },
  },
}
</script>
