<template>
  <div>
    <div style="display:flex;justify-content:center;"
      :style="{
        '--widget-template-color': popup.sections.styling.TemplateColor,
        zoom: popup.sections.styling.MaxWidth > 600 ? 600 / popup.sections.styling.MaxWidth : 1,
        paddingTop: `${widgetPaddingTop}px`,
      }">
      <div class="popup-widget" :style="containerStyle" >
        <span :style="closeButtonStyle" class="close-btn" @click="selectBlock('closeButton')">
          <svg id="IconChangeColor" width="200" height="200" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="mainIconPathAttribute" d="M14 14L34 34" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" :stroke="popup.sections.closeButton.Color"></path>
            <path id="mainIconPathAttribute" d="M14 34L34 14" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" :stroke="popup.sections.closeButton.Color"></path>
          </svg>
        </span>
        <template v-if="step === 1">
          <div v-if="popup.sections.styling.Align === 'left'" :style="rightEmailContentStyle" 
            class="widget-template" :class="popup.sections.styling.Template"
            @click="selectBlock('emailStep')">
            <WidgetTheme 
              v-if="popup.sections.styling.Template" :theme="popup.sections.styling.Template"
              :color="popup.sections.styling.BackgroundColor"
              :theme-color="popup.sections.styling.TemplateColor"
              :line-width="popup.sections.styling.LineWidth"
              :align="popup.sections.styling.Align"
            ></WidgetTheme>
          </div>
          <div :style="leftEmailContentStyle">
            <div class="widget-block" @click="selectBlock('emailStep', 'Settings')">
              <h1 :style="titleEmailStyle">{{ popup.sections.emailStep.Content.Title }}</h1>
              <p :style="descEmailStyle">
                {{ popup.sections.emailStep.Content.Description }}
              </p>
            </div>
            <div class="widget-block" @click="selectBlock('emailStep', 'Input')">
              <input
                type="email"
                :placeholder="popup.sections.emailStep.Input.Placeholder"
                :style="inputEmailStyle"
                readonly
              />
            </div>
            <div class="widget-block" @click="selectBlock('emailStep', 'Button')">
              <button :style="actionEmailStyle">
                {{ popup.sections.emailStep.Button.Label }}
              </button>
            </div>
          </div>
          <div v-if="popup.sections.styling.Align === 'right'" :style="rightEmailContentStyle" 
            class="widget-template" :class="`${popup.sections.styling.Template} right`"
            @click="selectBlock('emailStep')">
            <WidgetTheme 
              v-if="popup.sections.styling.Template" :theme="popup.sections.styling.Template"
              :color="popup.sections.styling.BackgroundColor"
              :theme-color="popup.sections.styling.TemplateColor"
              :line-width="popup.sections.styling.LineWidth"
              :align="popup.sections.styling.Align"
            ></WidgetTheme>
          </div>
        </template>
        <template v-if="step === 2">
          <div v-if="popup.sections.styling.Align === 'left'" :style="rightPhoneContentStyle" class="widget-template" :class="popup.sections.styling.Template"
            @click="selectBlock('phoneStep')">
            <WidgetTheme 
              v-if="popup.sections.styling.Template" :theme="popup.sections.styling.Template"
              :color="popup.sections.styling.BackgroundColor"
              :theme-color="popup.sections.styling.TemplateColor"
              :line-width="popup.sections.styling.LineWidth"
              :align="popup.sections.styling.Align"
            ></WidgetTheme>
          </div>
          <div :style="leftPhoneContentStyle">
            <div class="widget-block" @click="selectBlock('phoneStep', 'Settings')">
              <h1 :style="titlePhoneStyle">{{ popup.sections.phoneStep.Content.Title }}</h1>
              <p :style="descPhoneStyle">
                {{ popup.sections.phoneStep.Content.Description }}
              </p>
            </div>
            <div class="widget-block" @click="selectBlock('phoneStep', 'Input')">
              <input
                type="text"
                :placeholder="popup.sections.phoneStep.Input.Placeholder"
                :style="inputPhoneStyle"
                readonly
                @click="selectBlock('inputs')"
              />
            </div>
            <div class="widget-block" @click="selectBlock('phoneStep', 'Button')">
              <button :style="actionPhoneStyle">
                {{ popup.sections.phoneStep.Button.Label }}
              </button>
            </div>
            <div class="widget-block" style="margin-bottom: 0;" @click="selectBlock('phoneStep', 'Legal')">
              <p :style="legalPhoneStyle">
                {{ popup.sections.phoneStep.Content.Legal | legal }}
              </p>
            </div>
            <div v-if="popup.sections.phoneStep.NoThanks.Label" class="widget-block" @click="selectBlock('phoneStep', 'Settings')">
              <p :style="noThanksStyle">
                {{ popup.sections.phoneStep.NoThanks.Label }}
              </p>
            </div>
          </div>
          <div v-if="popup.sections.styling.Align === 'right'" :style="rightPhoneContentStyle" class="widget-template"
            :class="`${popup.sections.styling.Template} right`"
            @click="selectBlock('phoneStep')">
            <WidgetTheme 
              v-if="popup.sections.styling.Template" :theme="popup.sections.styling.Template"
              :color="popup.sections.styling.BackgroundColor"
              :theme-color="popup.sections.styling.TemplateColor"
              :line-width="popup.sections.styling.LineWidth"
              :align="popup.sections.styling.Align"
            ></WidgetTheme>
          </div>
        </template>
        <template v-if="step === 3">
          <div v-if="popup.sections.styling.Align === 'left'" :style="rightThanksStyle" class="widget-template" :class="popup.sections.styling.Template"
            @click="selectBlock('thanksStep')">
            <WidgetTheme 
              v-if="popup.sections.styling.Template" :theme="popup.sections.styling.Template"
              :color="popup.sections.styling.BackgroundColor"
              :theme-color="popup.sections.styling.TemplateColor"
              :line-width="popup.sections.styling.LineWidth"
              :align="popup.sections.styling.Align"
            ></WidgetTheme>
          </div>
          <div :style="leftThanksStyle" @click="selectBlock('thanksStep')">
            <div class="widget-block">
              <div :style="thanksStepStyle">
                {{  popup.sections.thanksStep.Content  }}
              </div>
              <p :style="descThanksStyle">
                {{ popup.sections.thanksStep.Desc }}
              </p>
            </div>
          </div>
          <div v-if="popup.sections.styling.Align === 'right'" :style="rightThanksStyle" class="widget-template"
            :class="`${popup.sections.styling.Template} right`"
            @click="selectBlock('thanksStep')">
            <WidgetTheme 
              v-if="popup.sections.styling.Template" :theme="popup.sections.styling.Template"
              :color="popup.sections.styling.BackgroundColor"
              :theme-color="popup.sections.styling.TemplateColor"
              :line-width="popup.sections.styling.LineWidth"
              :align="popup.sections.styling.Align"
            ></WidgetTheme>
          </div>
        </template>
      </div>
    </div>
    <div v-if="popup.sections.triggerButton.IsEnable" style="margin: 0 auto; margin-top: 30px;max-width: 100%;" :style="{ textAlign: popup.sections.triggerButton.Position, width: `${popup.sections.styling.MaxWidth}px` }">
      <button :style="triggerButtonStyle" @click="selectBlock('triggerButton')">
        <i
          v-if="popup.sections.triggerButton.Type === 'icon-left'"
          class="icon"
          :class="popup.sections.triggerButton.Icon"
        ></i>
        {{ popup.sections.triggerButton.Label }}
        <i
          v-if="popup.sections.triggerButton.Type === 'icon-right'"
          class="icon"
          :class="popup.sections.triggerButton.Icon"
        ></i>
      </button>
    </div>
  </div>
</template>

<script>
import WidgetTheme from './WidgetTheme.vue'

export default {
  components: {
    WidgetTheme
  },

  props: {
    popup: {
      type: [Object],
      default: null,
    },

    device: {
      type: String,
      default: 'desktop',
    },

    step: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      widgetPaddingTop: 0,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    triggerButtonStyle() {
      return {
        background: this.popup.sections.triggerButton.BackgroundColor,
        border: `1px solid ${this.popup.sections.triggerButton.BorderColor}`,
        color: this.popup.sections.triggerButton.Color,
        fontSize: `${this.popup.sections.triggerButton.FontSize}px`,
        borderRadius: `${this.popup.sections.triggerButton.Roundness}px`,
        padding: `${this.popup.sections.triggerButton.Padding}px`,
      }
    },

    closeButtonStyle() {
      return {
        background: this.popup.sections.closeButton.BackgroundColor,
        border: `3px solid ${this.popup.sections.closeButton.BorderColor}`,
        fontSize: `${this.popup.sections.closeButton.Size}px`,
        width: `${Number(this.popup.sections.closeButton.Size) + 10}px`,
        height: `${Number(this.popup.sections.closeButton.Size) + 10}px`,
        lineHeight: `${this.popup.sections.closeButton.Size}px`,
        borderRadius: '50%',
        zIndex: 10,
        position: 'absolute',
        top: `${this.popup.sections.closeButton.Top}px`,
        right: `${this.popup.sections.closeButton.Right}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.7)',
        fontFamily: "Arial, Helvetica, sans-serif",
      }
    },

    descEmailStyle() {
      return {
        fontSize: `${this.popup.sections.emailStep.Content.DescriptionFontSize}px`,
        color: this.popup.sections.emailStep.Content.DescriptionColor,
        textAlign: this.popup.sections.emailStep.Content.DescriptionAlign,
        width: '100%',
        padding: '0px',
        margin: '0px',
      }
    },

    leftEmailContentStyle() {
      let styles = {
        flex: '1 1 50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: this.selectedDevice === 'mobile' ? '15px' : `${this.popup.sections.emailStep.Content.PaddingY}px ${this.popup.sections.emailStep.Content.PaddingX}px`,
        flexDirection: 'column'
      }

      if (this.popup.sections.styling.Align === 'center') {
        styles.backgroundImage = `url(${this.popup.sections.emailStep.Content.BackgroundImg})`
        styles.backgroundSize = 'cover'
        styles.backgroundPosition = 'center'
        styles.boxShadow = `inset 0 0 0 2000px ${this.popup.sections.styling.BackgroundColor}`
      }

      return styles
    },

    titleEmailStyle() {
      return {
        fontSize: `${this.popup.sections.emailStep.Content.FontSize}px`,
        color: this.popup.sections.emailStep.Content.Color,
        textAlign: this.popup.sections.emailStep.Content.Align,
        width: '100%',
      }
    },

    inputEmailStyle() {
      return {
        backgroundColor: this.popup.sections.emailStep.Input.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.popup.sections.emailStep.Input.BorderColor || 'transparent',
        color: this.popup.sections.emailStep.Input.Color,
        display: 'inline-block',
        borderRadius: `${this.popup.sections.emailStep.Input.Roudness}px`,
        fontSize: `${this.popup.sections.emailStep.Input.FontSize}px`,
        lineHeight: `28px`,
        padding: `10px 16px`,
        width: '100%',
        marginTop: `${this.popup.sections.emailStep.Input.MarginTop}px`,
      }
    },

    actionEmailStyle() {
      return {
        width: '100%',
        marginTop: `${this.popup.sections.emailStep.Button.MarginTop}px`,
        background: this.popup.sections.emailStep.Button.BackgroundColor,
        border: `1px solid ${this.popup.sections.emailStep.Button.BorderColor}`,
        color: this.popup.sections.emailStep.Button.Color,
        padding: `${this.popup.sections.emailStep.Button.Padding}px 0px`,
        fontSize: `${this.popup.sections.emailStep.Button.FontSize}px`,
        borderRadius: `${this.popup.sections.emailStep.Button.Roudness}px`,
      }
    },

    rightEmailContentStyle() {
      let res = {
        backgroundImage: `url(${this.popup.sections.emailStep.Content.BackgroundImg})`,
        flex: '1 1 50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',
      }
      if (this.popup.sections.styling.Template === 'default')
      {
        res['border'] = `${this.popup.sections.styling.LineWidth}px solid ${this.popup.sections.styling.TemplateColor}`
      }
      return res
    },

    descPhoneStyle() {
      return {
        fontSize: `${this.popup.sections.phoneStep.Content.DescriptionFontSize}px`,
        color: this.popup.sections.phoneStep.Content.DescriptionColor,
        textAlign: this.popup.sections.phoneStep.Content.DescriptionAlign,
        padding: '0px',
        margin: '0px',
        width: '100%',
      }
    },

    legalPhoneStyle() {
      return {
        fontSize: `${this.popup.sections.phoneStep.Content.LegalFontSize}px`,
        color: this.popup.sections.phoneStep.Content.LegalColor,
        textAlign: this.popup.sections.phoneStep.Content.LegalAlign,
        margin: '0px',
        padding: '0px',
        marginTop: `${this.popup.sections.phoneStep.Content.LegalMarginTop}px`,
        width: '100%',
      }
    },

    leftPhoneContentStyle() {
      let styles = {
        flex: '1 1 50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${this.popup.sections.phoneStep.Content.PaddingY}px ${this.popup.sections.phoneStep.Content.PaddingX}px`,
        flexDirection: 'column'
      }

      if (this.popup.sections.styling.Align === 'center') {
        styles.backgroundImage = `url(${this.popup.sections.phoneStep.Content.BackgroundImg})`
        styles.backgroundSize = 'cover'
        styles.backgroundPosition = 'center'
        styles.boxShadow = `inset 0 0 0 2000px ${this.popup.sections.styling.BackgroundColor}`
      }

      return styles
    },

    titlePhoneStyle() {
      return {
        fontSize: `${this.popup.sections.phoneStep.Content.FontSize}px`,
        color: this.popup.sections.phoneStep.Content.Color,
        textAlign: this.popup.sections.phoneStep.Content.Align,
        width: '100%',
      }
    },

    inputPhoneStyle() {
      return {
        backgroundColor: this.popup.sections.phoneStep.Input.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.popup.sections.phoneStep.Input.BorderColor || 'transparent',
        color: this.popup.sections.phoneStep.Input.Color,
        display: 'inline-block',
        borderRadius: `${this.popup.sections.phoneStep.Input.Roudness}px`,
        fontSize: `${this.popup.sections.phoneStep.Input.FontSize}px`,
        lineHeight: `28px`,
        padding: `10px 16px`,
        marginTop: `${this.popup.sections.phoneStep.Input.MarginTop}px`,
        width: '100%',
      }
    },

    actionPhoneStyle() {
      return {
        width: '100%',
        marginTop: `${this.popup.sections.phoneStep.Button.MarginTop}px`,
        background: this.popup.sections.phoneStep.Button.BackgroundColor,
        border: `1px solid ${this.popup.sections.phoneStep.Button.BorderColor}`,
        color: this.popup.sections.phoneStep.Button.Color,
        padding: `${this.popup.sections.phoneStep.Button.Padding}px 0px`,
        fontSize: `${this.popup.sections.phoneStep.Button.FontSize}px`,
        borderRadius: `${this.popup.sections.phoneStep.Button.Roudness}px`,
      }
    },

    rightPhoneContentStyle() {
      let styles = {
        backgroundImage: `url(${this.popup.sections.phoneStep.Content.BackgroundImg})`,
        flex: '1 1 50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',
      }

      if (this.popup.sections.styling.Template === 'default')
      {
        styles['border'] = `${this.popup.sections.styling.LineWidth}px solid ${this.popup.sections.styling.TemplateColor}`
      }
      return styles
    },

    containerStyle() {
      return {
        background: this.popup.sections.styling.BackgroundColor,
        border: `${this.popup.sections.styling.BorderWidth}px solid ${this.popup.sections.styling.BorderColor}`,
        width: `${this.popup.sections.styling.MaxWidth}px`,
        minHeight: `${this.popup.sections.styling.MinHeight}px`,
        borderRadius: `${this.popup.sections.styling.Roudness}px`,
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 30px',
        overflow: 'hidden',
      }
    },

    leftThanksStyle() {
      let styles = {
        flex: '1 1 50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: `${this.popup.sections.thanksStep.PaddingY}px ${this.popup.sections.thanksStep.PaddingX}px`,
      }

      if (this.popup.sections.styling.Align === 'center') {
        styles.backgroundImage = `url(${this.popup.sections.thanksStep.BackgroundImg})`
        styles.backgroundSize = 'cover'
        styles.backgroundPosition = 'center'
        styles.boxShadow = `inset 0 0 0 2000px ${this.popup.sections.styling.BackgroundColor}`
      }

      return styles
    },

    rightThanksStyle() {
      let styles =  {
        backgroundImage: `url(${this.popup.sections.thanksStep.BackgroundImg})`,
        flex: '1 1 50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',
      }

      if (this.popup.sections.styling.Template === 'default')
      {
        styles['border'] = `${this.popup.sections.styling.LineWidth}px solid ${this.popup.sections.styling.TemplateColor}`
      }
      return styles
    },

    thanksStepStyle() {
      return {
        background: this.popup.sections.thanksStep.BackgroundColor,
        color: this.popup.sections.thanksStep.Color,
        textAlign: this.popup.sections.thanksStep.Align,
        fontSize: `${this.popup.sections.thanksStep.FontSize}px`,
        width: '100%'
      }
    },

    descThanksStyle() {
      return {
        color: this.popup.sections.thanksStep.DescColor,
        textAlign: this.popup.sections.thanksStep.DescAlign,
        fontSize: `${this.popup.sections.thanksStep.DescFontSize}px`,
        marginTop: `${this.popup.sections.thanksStep.DescMarginTop}px`,
        width: '100%'
      }
    },

    noThanksStyle() {
      return {
        cursor: 'pointer',
        padding: '0px',
        margin: '0px',
        marginTop: `${this.popup.sections.phoneStep.NoThanks.MarginTop}px`,
        color: this.popup.sections.phoneStep.NoThanks.Color,
        fontSize: `${this.popup.sections.phoneStep.NoThanks.FontSize}px`,
        textAlign: 'center',
      }
    }
  },

  mounted() {
    window.addEventListener('scroll', () => {
      this.widgetPaddingTop = window.scrollY
    })
  },

  methods: {
    selectBlock(block, tab) {
      this.$emit('selected-block', { block, tab })
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-widget {
  background-color: transparent;

  .widget-template {
    div {
      position: absolute;
      width: 50%;
      right: -20%;
      top: -10%;
      height: 120%;
      svg {
        height: 104%;
        width: 103%;
      }
    }
    &.right {
      div {
        right: initial;
        left: -20%;
      }
    }
    &.default {
      border-top: none!important;
      border-bottom: none!important;
      &.right {
        border-right: none!important;
      }
      &:not(.right) {
        border-left: none!important;
      } 
    }
  }
  .powered-by-content {
    color: #666;
    padding-right: 10px;
    padding-top: 5px;
    display: block;
    font-size: 14px;
    a {
      color: #666;
      font-weight: bold;
      &:hover {
        text-decoration: underline!important;
      };
    }
  }
  .widget-block {
    border: 2px dashed rgba(0, 27, 72, 0.12);
    border-radius: 8px;
    cursor: pointer;
    transition: border 300ms ease, background-color 300ms ease;
    margin-bottom: 15px;
    position: relative;
    width: 100%;
    &.widget-content {
      border-color: transparent;
    }
    &:hover {
      border-color: #2399f0 !important;
      background-color: rgba(35, 153, 240, 0.05);
    }
  }
  .widget-toggle {
    margin-top: 25px;
    text-align: right;
    cursor: pointer;
  }
  .widget-default {
    text-align: center;
    img {
      width: 40px;
      margin: 20px;
    }
    .widget-optional {
      opacity: 0.5;
    }
    .widget-caption {
      color: rgba(0, 27, 72, 0.54);
      font-size: 16px;
    }
  }
  .widget-caption {
    margin-bottom: 20px;
  }
  .widget-copyright {
    text-align: center;
    margin-top: 16px;
    a {
      font-size: 13px;
      color: rgba(0, 27, 72, 0.54);
    }
  }
  .phone-input {
    margin-bottom: 15px;
    position: relative;
    color: #414141;
    .phone-code {
      position: absolute;
      top: 10px;
      padding: 0px 8px;
      img {
        width: 24px;
        margin-right: 5px;
      }
      .code {
        font-weight: 600;
        font-size: 12px;
      }
    }
    input {
      border: 1px solid #ced4da;
      padding: 10px 12px;
      padding-left: 60px;
      font-weight: 600;
      display: block;
      border-radius: 5px;
      width: 100%;
    }
  }
}
</style>
