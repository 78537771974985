<template>
  <div v-if="section" class="col-md-12">
    <NumberInput v-model="section.Timeout" label="Auto Popup Delay" unit="sec"
      tooltip="The Auto Pop-up Delay feature allows you to control the timing of pop-up messages or notifications on your device. Adjusting the delay ensures that pop-ups appear at the most convenient moment for you" />

    <label>Auto Popup Every<span v-b-tooltip.hover class="cursor-pointer pl-1"
        title="The Auto Pop-up Every feature allows you to set a recurring interval for pop-up messages or notifications on your device. This ensures that you receive timely reminders or updates at regular intervals."><i
          class="uil uil-question-circle"></i></span></label>
    <div class="row">
      <div class="col-md-6">
        <select v-model="section.Duration" class="form-control">
          <option v-for="(value, index) in durationOptions" :key="`options-${index}`" :value="index + 1">{{ index + 1 }}
          </option>
        </select>
      </div>
      <div class="col-md-6">
        <select v-model="section.Period" class="form-control">
          <option value="minutes">minutes</option>
          <option value="hour(s)">hour(s)</option>
          <option value="day(s)">day(s)</option>
          <option value="week(s)">week(s)</option>
          <option value="month(s)">month(s)</option>
          <option value="year(s)">year(s)</option>
        </select>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12 mb-3">
        <div class="d-flex">
          <label class="mr-4">Popup on Exit Intent<span v-b-tooltip.hover class="cursor-pointer pl-1"
              title="The Popup on Exit Intent feature triggers a pop-up message or notification when a user attempts to leave a website or close a webpage."><i
                class="uil uil-question-circle"></i></span></label>
          <b-form-checkbox v-model="section.PopUpOnExit" switch></b-form-checkbox>
        </div>
      </div>
    </div>
    <div v-if="section.PopUpOnExit" class="row">
      <div class="col-md-12"><label>Duration</label></div>
      <div class="col-md-6">
        <select v-model="section.PopUpOnExitDuration" class="form-control">
          <option v-for="(value, index) in durationOptions" :key="`options-${index}`" :value="index + 1">{{ index + 1 }}
          </option>
        </select>
      </div>
      <div class="col-md-6">
        <select v-model="section.PopUpOnExitPeriod" class="form-control">
          <option value="minutes">minutes</option>
          <option value="hour(s)">hour(s)</option>
          <option value="day(s)">day(s)</option>
          <option value="week(s)">week(s)</option>
          <option value="month(s)">month(s)</option>
          <option value="year(s)">year(s)</option>
        </select>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12 mb-3">
        <div class="d-flex">
          <label class="mr-4">Scheduling<span v-b-tooltip.hover class="cursor-pointer pl-1"
              title="The Scheduling a Pop-up feature allows you to set specific dates and times for a pop-up message or notification to appear on your device."><i
                class="uil uil-question-circle"></i></span></label>
          <b-form-checkbox v-model="section.IsScheduled" switch></b-form-checkbox>
        </div>
      </div>
    </div>
    <div v-if="section.IsScheduled" class="row mt-2">
      <div class="col-6">
        <label class="mr-4">From</label>
        <TimePicker v-model="section.TimeFrom" />
      </div>
      <div class="col-6">
        <label class="mr-4">To</label>
        <TimePicker v-model="section.TimeTo" />
      </div>
      <div class="col-md-12">
        <SelectInput v-model="section.Timezone" :options="timezones" label="Timezone" />
      </div>
    </div>
  </div>
</template>

<script>
import NumberInput from '../../Coupons/Components/NumberInput'

export default {
  components: {
    NumberInput
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      timePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'G:i K',
      },
    }
  },

  computed: {
    durationOptions() {
      return new Array(60)
    },

    timezones() {
      return this.$store.getters['app/timezones']
    },
  }
}
</script>