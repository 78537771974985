<template>
    <div v-if="section">
      <b-tabs v-model="tabIndex">
        <b-tab title="Settings" active>
          <div class="row">
            <div class="col-md-12">
              <ImageInput v-model="section.Content.BackgroundImg" label="Background image" />
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Title</label>
                <input v-model="section.Content.Title" type="text" class="form-control" />
              </div>
            </div>
            <div class="col-md-6">
              <NumberInput v-model="section.Content.PaddingX" label="Padding X" />
            </div>
            <div class="col-md-6">
              <NumberInput v-model="section.Content.PaddingY" label="Padding Y" />
            </div>
            <div class="col-md-6">
              <NumberInput v-model="section.Content.FontSize" label="Font size" />
            </div>
            <div class="col-md-6">
              <ColorInput v-model="section.Content.Color" label="Color" />
            </div>
            <div class="col-md-12">
              <AlignInput v-model="section.Content.Align" />
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Description</label>
                <textarea v-model="section.Content.Description" rows="5" class="form-control"></textarea>
              </div>
            </div>
            <div class="col-md-6">
              <ColorInput v-model="section.Content.DescriptionColor" label="Color" />
            </div>
            <div class="col-md-6">
              <NumberInput v-model="section.Content.DescriptionFontSize" label="Font size" />
            </div>
            <div class="col-md-12">
              <AlignInput v-model="section.Content.DescriptionAlign" />
            </div>
          </div>
        </b-tab>
        <b-tab title="Input">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Placeholder</label>
                <input v-model="section.Input.Placeholder" type="text" class="form-control" placeholder="Your Email" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="d-flex mb-3 coupon-input-checkbox">
                <b-form-checkbox v-model="section.Input.IsRequired" name="emailRequired">
                  Required
                </b-form-checkbox>
              </div>
            </div>
            <div class="col-md-6">
              <ColorInput v-model="section.Input.BackgroundColor" label="Background" />
            </div>
            <div class="col-md-6">
              <ColorInput v-model="section.Input.BorderColor" label="Border color" />
            </div>
            <div class="col-md-6">
              <NumberInput v-model="section.Input.Roudness" label="Corner roudness" />
            </div>
            <div class="col-md-6">
              <ColorInput v-model="section.Input.Color" label="Color" />
            </div>
            <div class="col-md-6">
              <NumberInput v-model="section.Input.MarginTop" label="MarginTop" />
            </div>
          </div>
        </b-tab>
        <b-tab title="Button">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Label</label>
                <input v-model="section.Button.Label" type="text" class="form-control"  />
              </div>
            </div>
            <div class="col-md-6">
              <ColorInput v-model="section.Button.BackgroundColor" label="Background" />
            </div>
            <div class="col-md-6">
              <ColorInput v-model="section.Button.BorderColor" label="Border color" />
            </div>
            <div class="col-md-6">
              <ColorInput v-model="section.Button.Color" label="Color" />
            </div>
            <div class="col-md-6">
              <NumberInput v-model="section.Button.Roudness" label="Corner roudness" />
            </div>
            <div class="col-md-6">
              <NumberInput v-model="section.Button.FontSize" label="Font size" />
            </div>
            <div class="col-md-6">
              <NumberInput v-model="section.Button.MarginTop" label="MarginTop" />
            </div>
            <div class="col-md-6">
              <NumberInput v-model="section.Button.Padding" label="Padding" />
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </template>
  
  <script>
  import NumberInput from '../../Coupons/Components/NumberInput'
  import ColorInput from '../../Coupons/Components/ColorInput'
  import ImageInput from '../../Coupons/Components/ImageInput'
  import AlignInput from '../../Coupons/Components/AlignInput'
  
  export default {
    components: {
      ColorInput,
      NumberInput,
      AlignInput,
      ImageInput,
    },
  
    props: {
      section: {
        type: [Object],
        default: null,
      },

      tab: {
        type: String,
        default: null,
      }
    },

    data() {
      return {
        tabIndex: 0,
      }
    },

    watch: {
      tab(val) {
        switch(val) {
          case 'Settings':
            this.tabIndex = 0;
            break;
          case 'Input':
            this.tabIndex = 1;
            break;
          case 'Button':
            this.tabIndex = 2;
            break;
          default:
            this.tabIndex = 0;
            break;
        }
      }
    }
  }
  </script>
  
  <style lang="scss">
  .coupon-input-checkbox {
    .custom-checkbox {
      padding-left: 30px;
      margin-bottom: 5px;
    }
  
    .custom-control-label:before, .custom-control-label:after {
      width: 20px;
      height: 20px;
      left: -30px;
      top: 0px;
    }
  
    .custom-control-input {
      width: 20px;
      height: 20px;
      top: -4px;
    }
  }
  </style>
  