<template>
  <div v-if="section" class="row">
    <div class="col-md-12">
      <ImageInput v-model="section.BackgroundImg" label="Background image" />
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Content</label>
        <input v-model="section.Content" type="text" class="form-control" />
      </div>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.Color" label="Color" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.FontSize" label="Font size" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.PaddingX" label="Padding X" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.PaddingY" label="Padding Y" />
    </div>
    <div class="col-md-12">
      <AlignInput v-model="section.Align"/>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Description</label>
        <input v-model="section.Desc" type="text" class="form-control" />
      </div>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.DescColor" label="Color" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.DescFontSize" label="Font size" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.DescMarginTop" label="Margin Top" />
    </div>
    <div class="col-md-12">
      <AlignInput v-model="section.DescAlign"/>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Redirect URI</label>
        <input v-model="section.RedirectURI" type="text" class="form-control" />
      </div>
    </div>
  </div>
</template>

<script>
  import NumberInput from '../../Coupons/Components/NumberInput'
  import ColorInput from '../../Coupons/Components/ColorInput'
  import AlignInput from '../../Coupons/Components/AlignInput'
  import ImageInput from '../../Coupons/Components/ImageInput'
  
  export default {
    components: {
      NumberInput,
      ColorInput,
      AlignInput,
      ImageInput
    },
  
    props: {
      section: {
        type: [Object],
        default: null,
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .form-group {
    position: relative;
  }
  </style>
  