<template>
    <div v-if="section">
      <div class="row">
        <div class="col-md-6">
          <ColorInput v-model="section.BackgroundColor" label="Background" />
        </div>
        <div class="col-md-6">
          <ColorInput v-model="section.BorderColor" label="Border color" />
        </div>
        <div class="col-md-6">
          <ColorInput v-model="section.Color" label="Color" />
        </div>
        <div class="col-md-6">
          <NumberInput v-model="section.Size" label="Size" />
        </div>
        <div class="col-md-6">
          <NumberInput v-model="section.Top" label="Top" />
        </div>
        <div class="col-md-6">
          <NumberInput v-model="section.Right" label="Right" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ColorInput from '../../Coupons/Components/ColorInput'
  import NumberInput from '../../Coupons/Components/NumberInput'
  
  export default {
    components: {
      ColorInput,
      NumberInput,
    },
  
    props: {
      section: {
        type: [Object],
        default: null,
      },
    },
  }
  </script>
  
  <style></style>
  